import React, { useState, useEffect, forwardRef, Fragment } from "react";
import styled from "styled-components";
import { Typography, Radio } from "antd";
// import MaterialTable from "material-table";
import MUIDataTable from "mui-datatables";
import { DatePicker, Space } from "antd";
const { RangePicker } = DatePicker;
import { message } from "antd";

import AddBox from "@material-ui/icons/AddBox";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Check from "@material-ui/icons/Check";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Clear from "@material-ui/icons/Clear";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import FilterList from "@material-ui/icons/FilterList";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import Remove from "@material-ui/icons/Remove";
import SaveAlt from "@material-ui/icons/SaveAlt";
import Search from "@material-ui/icons/Search";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { useDispatch, useSelector } from "react-redux";
import AppConatants from "../../../constants/appConstants";
import {
  AllOrdersCrmDetails,
  DownloadAll,
} from "../../../redux/slices/crmSlice";
import moment from "moment";

const { Title } = Typography;
const Wrapper = styled.div`
  width: 100%;
`;
const BodyContainer = styled.div``;

const RadioContainer = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  padding: 5px 0;
  > div {
    color: #000000;
    font-size: 18px;
    font-weight: bold;
    margin-right: 30px;
  }
`;

const AllOrdersCRM = () => {
  const dispatch = useDispatch();
  const { data, total_orders_length } = useSelector(
    (state) => state.crm.data.AllOrdersCrmDetails
  );
  // console.log(data, "total_orders_length");

  const allOrdersCrm = data?.map((o) => ({ ...o }));
  const allDatacount = total_orders_length;
  // const
  const [page, setPage] = useState(1);
  const [exeededcondition, setexeededcondition] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const [dateData, setDateData] = useState({
    start_date: "",
    end_date: "",
  });
  useEffect(() => {
    if (data) {
      const processed = data.map((o) => ({ ...o }));
      if (searchText) {
        const filtered = processed.filter((row) =>
          Object.values(row).some((value) =>
            String(value).toLowerCase().includes(searchText.toLowerCase())
          )
        );
        setFilteredData(filtered);
      } else {
        setFilteredData(processed);
      }
    }
  }, [data, searchText]);

  const onDateChange = (value, dateString) => {
    setDateData({
      ...dateData,
      start_date: dateString[0],
      end_date: dateString[1],
    });
    setPage(1);
  };
  useEffect(() => {
    let data = {};
    data.start_date = dateData?.start_date;
    data.end_date = dateData?.end_date;
    data.page = page;
    dispatch(AllOrdersCrmDetails(data));
  }, [page]);

  const dateRangeCheck = async (e) => {
    e.preventDefault();
    if (exeededcondition) {
      message.error("Date range cannot exceed 62 days");
      return;
    }
    console.log(page, "page");
    let data = {};
    data.start_date = dateData.start_date;
    data.end_date = dateData.end_date;
    data.page = page;
    dispatch(AllOrdersCrmDetails(data));
    ////not dispatching yet rtk
  };

  const handleDownloadAll = async () => {
    let data = {
      start_date: dateData.start_date,
      end_date: dateData.end_date,
    };

    if (exeededcondition) {
      message.error("Date range cannot exceed 62 days");
      return;
    }

    try {
      const response = await fetch(
        `${AppConatants.baseURL}/crm/downloadallorders`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      if (!response.ok) {
        throw new Error("Failed to download file");
      }

      // Convert response to blob
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);

      // Trigger download
      const a = document.createElement("a");
      a.href = url;
      a.download = `allorders${Date.now()}.csv`; // Set the file name
      document.body.appendChild(a);
      a.click();

      // Cleanup
      a.remove();
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
      message.error(`Error downloading file: ${error.message}`);
    }
  };

  const disabledDate = (current) => {
    return current && current > moment().endOf("day");
  };

  // Custom date selection limit
  const onCalendarChange = (dates, dateStrings, info) => {
    if (dates) {
      // If a start date is selected (first selection)
      if (dates[0] && !dates[1]) {
        // Do nothing, wait for end date selection
        return;
      }

      // If both dates are selected, check the range
      if (dates[0] && dates[1]) {
        const days = dates[1].diff(dates[0], "days");
        if (days > 62) {
          setexeededcondition(true);
          // If range is more than 62 days, prevent selection
          message.error("Date range cannot exceed 62 days");
          return;
        } else {
          setexeededcondition(false);
          // message.error("Date range cannot exceed 62 days");
        }
      }
    }
  };

  const tableIcons = {
    Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
    Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
    Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
    DetailPanel: forwardRef((props, ref) => (
      <ChevronRight {...props} ref={ref} />
    )),
    Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
    Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
    Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
    FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
    LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
    NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
    PreviousPage: forwardRef((props, ref) => (
      <ChevronLeft {...props} ref={ref} />
    )),
    ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
    Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
    SortArrow: forwardRef((props, ref) => (
      <ArrowDownward {...props} ref={ref} />
    )),
    ThirdStateCheck: forwardRef((props, ref) => (
      <Remove {...props} ref={ref} />
    )),
    ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
  };

  const tableColumns = [
    {
      name: "id",
      label: "ID",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => value || "-",
      },
    },
    {
      name: "unique_id",
      label: "Unique ID",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => value || "-",
      },
    },
    {
      name: "uid",
      label: "User ID",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => value || "-",
      },
    },
    {
      name: "name",
      label: "User Name",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => value || "-",
      },
    },
    {
      name: "ts_name",
      label: "Store/Task Name",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => value || "-",
      },
    },
    {
      name: "camp_name",
      label: "Campaign Name",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => value || "-",
      },
    },
    {
      name: "partner",
      label: "Partner",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => value || "-",
      },
    },
    {
      name: "status",
      label: "Status",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => value || "-",
      },
    },
    {
      name: "sale_amount",
      label: "Sale Amount",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => value || "-",
      },
    },
    {
      name: "click_id",
      label: "Click ID",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => value || "-",
      },
    },
    {
      name: "order_id",
      label: "Order ID",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => value || "-",
      },
    },
    {
      name: "payout",
      label: "Payout",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => value || "-",
      },
    },
    {
      name: "point_post_payout",
      label: "Point Post Payout",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => value || "-",
      },
    },
    {
      name: "conversion_date",
      label: "Conversion Date",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => value || "-",
      },
    },
    {
      name: "action_date",
      label: "Action Date",
      options: {
        filter: true,
        sort: true,
        customBodyRender: (value) => value || "-",
      },
    },
  ];
  const options = {
    filterType: "checkbox",
    selectableRows: "none",
    responsive: "vertical",
    rowsPerPage: 100,
    rowsPerPageOptions: [100],
    count: total_orders_length,
    page: page - 1,
    onChangePage: (currentPage) => {
      console.log(currentPage, "currentPage");
      setPage(currentPage + 1);
    },
    onSearchChange: (searchText) => {
      setSearchText(searchText || "");
    },
    searchProps: {
      onKeyUp: (e) => {
        if (e.key === "Enter") {
          setSearchText(e.target.value);
        }
      },
    },
    pagination: true,
    serverSide: true,
    search: true,
    viewColumns: true,
    enableNestedDataAccess: ".",

    searchPlaceholder: "Search in all columns...",
    textLabels: {
      pagination: {
        next: "Next",
        previous: "Previous",
        rowsPerPage: "100",
        displayRows: "of",
      },
    },
  };

  return (
    <Fragment>
      <div
        className="my-3"
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <div>
          <label>Date Range:--</label>
          {/* <RangePicker onChange={onDateChange} /> */}
          <RangePicker
            onChange={onDateChange}
            onCalendarChange={onCalendarChange}
            disabledDate={disabledDate}
            showTime={false}
            // format="YYYY-MM-DD"
          />
          <button className="btn btn-primary ml-4" onClick={dateRangeCheck}>
            Submit
          </button>
        </div>
        <div>
          <button
            className="btn btn-primary ml-4"
            onClick={() => handleDownloadAll()}
          >
            Download All
          </button>
        </div>
      </div>
      {/* <MaterialTable
        title="Complete Orders Details.. "
        columns={tableColumns}
        icons={tableIcons}
        data={allOrdersCrm && allOrdersCrm}
        // options={{ search: true, actionsColumnIndex: -1, exportButton: true, allColumns: true,}}
        options={{
          actionsColumnIndex: -1,
          selection: true,
          showFirstLastPageButtons: true,
          pageSize: 10,
          padding: "dense",
          pageSizeOptions: [10, 20, allOrdersCrm ? allOrdersCrm.length : 100],
          exportButton: true,
        }}
      /> */}
      <MUIDataTable
        title={"Complete User Details"}
        rowsPerPage={10} // Ensure this is a number, not a string
        rowsPerPageOptions={[100]} // Options should be an array of numbers
        data={filteredData ?? []}
        columns={tableColumns}
        options={options}
        // options={{
        //   filterType: "checkbox", // Optional: Define filter behavior
        //   selectableRows: "none", // Optional: Disable row selection
        //   responsive: "vertical", // Optional: Make the table responsive
        // }}
      />
    </Fragment>
  );
};

export default AllOrdersCRM;
